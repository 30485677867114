import * as React from "react"
import {  graphql } from "gatsby"
//import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const About = ({ data, location }) => {
 const aboutTitle = data.site.siteMetadata?.title || `About me`

    return (
     <Layout location={location} title={aboutTitle}>
      <Seo title="About page" />
      <h1>I am Clint Grove</h1>
      <p>I do the work of a Data Engineer, Data Architect and Cloud Solution Architect. I also dabble in Infrastructure as Code. I am currently involved in Azure Verified Modules project. You can find it at https://aka.ms/avm </p>
      <p>Over the years I have been involved in projects that used ERP and CRM systems as a source for financial and HR reporting to other operational data systems for Financial, Hotel, Construction and Mining industries. 
      <br/>
      <br/>
        Since 2017 I have worked in projects using Azure cloud services to build data systems. 
        <br/>
        <br/>
        I have experience in building cloud data infrastructure with ARM templates, Bicep and Terraform. Deployed through Continuous deployment and continous integration in Azure DevOps. 
        <br/>
        <br/>
        <p>I have a full time job so please <a href="mailto:clintdatagroove@gmail.com">contact me</a> so we can find a suitable time for training or mentoring </p>
        I can mentor and train people in:
        <li>Power BI</li>
        <li>Data Factory</li>
        <li>Databricks</li>
        <li>Synapse</li>
        <li>SQL Servers</li>
        <li>Logic Apps</li>
        <li>Infrastructure as Code (to intermediate level)</li>
      </p>
      <p>I provide mentoring and training on many data resources in Azure.</p>
      <p>Along with this experience I have knowledge and can train people in using their Office 365 products. 
        SharePoint, Calender and Outlook. 
      </p>
  
      <p>If you have a subscription to Office 365 for Small business and you would like to know how to use it for you and your staff please reach out to me. </p>
    

      
     </Layout>
    );

};

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
